import PropTypes from "prop-types";
import React from "react";

import Utilities from "@/utils/helpers/formatting";

import defaultStyles from "./index.module.scss";

const SecondaryHeadline = ({ text, styles }) => {
  const moduleStyles = { ...defaultStyles, ...styles };
  return (
    <h2 className={moduleStyles.secondaryHeadline}>
      {Utilities.renderMultiLine(text)}
    </h2>
  );
};

SecondaryHeadline.defaultProp = {
  styles: {},
  text: "",
};

SecondaryHeadline.propTypes = {
  styles: PropTypes.object,
  text: PropTypes.string.isRequired,
};

export default SecondaryHeadline;
